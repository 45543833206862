import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import QRCode from "qrcode";
import Loading from "components/Loading/Loading";
import LoginModal from "../../containers/PageLogin/LoginModal";
import { useDispatch, useSelector } from "react-redux";

const Royalty: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [srcUser, setSrcUser] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(true);
  useEffect(() => {
    if (user?._id) {
      QRCode.toDataURL(user._id)
        .then(setSrcUser)
        .catch((err: any) => console.error("QR Code generation error:", err));
    }
    setLoading(false);
    setIsLoginModalOpen(true);
  }, [user?._id]); // Add user?._id as a dependency

  //   console.log({ user });

  if (!user) {
    setLoading(false);
    setIsLoginModalOpen(true);
  }
  return (
    <>
      {loading && <Loading />}
      {isLoginModalOpen && (
        <div>
          <p>login Modal open</p>
          <LoginModal
            isOpen={isLoginModalOpen}
            closeModal={() => setIsLoginModalOpen(false)}
          />
        </div>
      )}

      {!loading && (
        <div className="container mx-auto p-4">
          {/* Title Section */}
          <div className="text-center">
            <h2 className="text-2xl font-bold">
              Welcome "{user?.displayName}"
            </h2>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-2"></div>
          </div>

          {/* QR Code Section */}
          <div className="mt-6 text-center">
            <h4 className="text-xl font-semibold text-gray-900">
              Your Unit QR code
            </h4>
            <p className="text-gray-700">
              to receive Stamp Reward in Royalty Program
            </p>

            {/* Divider for Mobile */}
            <div className="block md:hidden border-b border-gray-300 my-4"></div>

            {/* QR Code Image */}
            <div className="w-[300px] md:w-[400px] mx-auto mt-8">
              <img
                src={srcUser}
                className="w-full min-w-[300px] mx-auto"
                alt="QR Code"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Royalty;
