import { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { signup } from "../../redux/authSlice";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { setUser } from "../../redux/features/userSlice";

const SignupForm = ({ switchAuthState }) => {
  const dispatch = useDispatch();
  const [isLoginRequest, setIsLoginRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const cloud_name = "dg89sorey";
  const upload_preset = "x4mfna2o";

  const uploadCloudImage = async (file) => {
    if (
      !file ||
      !["image/jpeg", "image/jpg", "image/png"].includes(file.type)
    ) {
      return toast.error("Invalid image format");
    }

    const imageData = new FormData();
    imageData.append("file", file);
    imageData.append("upload_preset", upload_preset);

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const xhr = new XMLHttpRequest();

      // Configure the request
      xhr.open(
        "POST",
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        true
      );

      // Track progress
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          setUploadProgress(percentComplete);
        }
      });

      // Handle successful upload
      xhr.onload = async () => {
        if (xhr.status === 200) {
          const imgData = JSON.parse(xhr.responseText);
          setImagePreview(imgData.url);
          signupForm.setFieldValue("photo", imgData.url);
          toast.success("Image uploaded successfully");
        } else {
          toast.error("Image upload failed");
        }
        setIsUploading(false);
      };

      // Handle errors
      xhr.onerror = () => {
        toast.error("Image upload failed");
        setIsUploading(false);
      };

      // Send the request
      xhr.send(imageData);
    } catch (error) {
      toast.error("Image upload failed");
      setIsUploading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
    uploadCloudImage(file);
  };

  const signupForm = useFormik({
    initialValues: {
      username: "",
      displayName: "",
      password: "",
      confirmPassword: "",
      photo: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Username must be at least 3 characters")
        .required("Required"),
      displayName: Yup.string()
        .min(3, "Display name must be at least 3 characters")
        .required("Required"),
      password: Yup.string()
        .min(3, "Password must be at least 3 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      setErrorMessage("");
      setIsLoginRequest(true);
      let responseSignUp = dispatch(signup(values));

      console.log("value sign up --->", values);
      console.log("Formik Errors Before Submit:", signupForm.errors);

      if (responseSignUp) {
        signupForm.resetForm();
        setIsLoginRequest(false);
        dispatch(setAuthModalOpen(false));
        toast.success("Sign up successful");
      }
    },
  });

  return (
    <form onSubmit={signupForm.handleSubmit} className="space-y-4">
      <input
        type="text"
        name="username"
        placeholder="E-mail"
        className="w-full px-4 py-2 border rounded-md"
        onChange={signupForm.handleChange}
        onBlur={signupForm.handleBlur} // Required for Formik validation to trigger on blur
        value={signupForm.values.username}
      />

      <input
        type="text"
        name="displayName"
        placeholder="Display Name"
        className="w-full px-4 py-2 border rounded-md"
        onChange={signupForm.handleChange}
        value={signupForm.values.displayName}
      />

      <input
        type="password"
        name="password"
        placeholder="Password"
        className="w-full px-4 py-2 border rounded-md"
        onChange={signupForm.handleChange}
        value={signupForm.values.password}
      />

      <input
        type="password"
        name="confirmPassword"
        placeholder="Confirm Password"
        className="w-full px-4 py-2 border rounded-md"
        onChange={signupForm.handleChange}
        value={signupForm.values.confirmPassword}
      />

      {/* Avatar Upload Section */}
      <div className="flex items-center gap-3">
        {imagePreview ? (
          <img
            src={imagePreview}
            alt="avatar"
            className="h-12 w-12 rounded-full"
          />
        ) : (
          <span className="h-12 w-12 rounded-full bg-gray-300 flex items-center justify-center">
            A
          </span>
        )}

        <label className="px-4 py-2 border rounded-md cursor-pointer">
          Upload Avatar
          <input
            type="file"
            name="photo"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
      </div>

      {/* Progress Bar */}
      {isUploading && (
        <div className="w-full bg-gray-200 h-2 rounded-md mt-2 overflow-hidden">
          <div
            className="h-full bg-green-500 transition-all"
            style={{ width: `${uploadProgress}%` }}
          />
        </div>
      )}

      <button
        type="submit"
        className="w-full bg-red-400 text-white py-2 rounded-md hover:bg-red-600"
        disabled={isLoginRequest}
      >
        {isLoginRequest ? "Signing Up..." : "Sign Up"}
      </button>

      <button
        type="button"
        onClick={switchAuthState}
        className="w-full text-center text-gray-600 hover:text-gray-800"
      >
        Sign In
      </button>

      {errorMessage && (
        <p className="text-red-500 text-sm text-center">{errorMessage}</p>
      )}
    </form>
  );
};

export default SignupForm;
