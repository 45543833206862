import React from "react";
import { format } from "timeago.js";

const AboutUsModal = ({
  expandedAboutus,
  handleAboutUsExpandClick,
  operator,
}) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        expandedAboutus ? "block" : "hidden"
      }`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={handleAboutUsExpandClick}
      ></div>
      <div className="relative p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
        {/* Avatar section */}
        <div className="flex items-center justify-center mt-[-5rem]">
          <div className="relative">
            <div className="absolute right-0 bottom-0 w-6 h-6 rounded-full bg-green-500 z-10"></div>
            <img
              src={operator[0].photo}
              alt="operator avatar"
              className="w-24 h-24 border-2 border-black object-cover rounded-full backdrop-blur-sm"
            />
          </div>
        </div>
        <div className="flex flex-col items-center mt-5">
          <p className="text-lg uppercase text-center text-red-500">
            {operator[0].name}
          </p>
          <p className="text-xl font-bold caplitalise text-center text-black mt-5">
            About us
          </p>
        </div>
        <div className="flex flex-col items-center mt-5">
          <p className="text-sm capitalise text-center text-neutral-500">
            {operator[0].bio}
          </p>
          <p className="text-sm capitalise text-center text-neutral-500 mt-2">
            <span className="font-bold">Tel: </span> {operator[0].phone}
          </p>
          <p className="text-sm capitalise text-center text-neutral-500 m-2">
            <span className="font-bold">Tel: </span> {operator[0].phone}
          </p>
          <p className="text-sm capitalise text-center text-neutral-500 m-2">
            <span className="font-bold">Open: </span>{" "}
            {operator[0]?.createdAt
              ? format(new Date(operator[0]?.createdAt), "MMMM dd, yyyy")
              : "Unknown"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsModal;
