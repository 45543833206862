import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet-async";
import "./style.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import { CiFacebook } from "react-icons/ci";
import { CiInstagram } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { IoQrCodeOutline } from "react-icons/io5";
import { FaRegAddressCard } from "react-icons/fa";
import { TiTags } from "react-icons/ti";
import { useParams } from "react-router";
import { useEffect } from "react";
import operatorApi from "api/operator/operator.api";
import { Link } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import chatApi from "api/chat/chat.api";
import Subscriber from "components/LikeDislikeSubcribe/Subscriber";
import Comments from "components/LikeDislikeSubcribe/Comments";
import Royalty from "components/LikeDislikeSubcribe/Royalty";
import axios from "axios";
import { baseURL } from "configs/axiosConfig";
import View from "components/LikeDislikeSubcribe/View";
import { toast } from "react-toastify";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import AvatarDropdown from "components/Header/AvatarDropdown";
import { format } from "timeago.js";
import Bio from "components/Text/Bio";

export interface AuthorPageProps {
  className?: string;
}

export interface Operator {
  _id: string;
  name?: string;
  email?: string;
  password?: string;
  isAdmin?: boolean;
  coverPhoto?: string;
  photo?: string;
  phone?: string;
  address?: string;
  bio?: string;
  quote?: string;
  author?: string;
  __v?: number;
  createdAt?: string;
  updatedAt?: string;
}

interface User {
  id: string;
  name: string;
  email: string;
}

interface SubscriberProps {
  userTo: string;
  userFrom: string;
  refreshFunction?: (newSubscribe: any) => void;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [categories] = useState(["QR Menu", "Loyalty", "Best Deals"]);
  const [isLoading, setIsLoading] = useState(true);
  const [CommentLists, setCommentLists] = useState([]);
  const [follows, setFollows] = useState(0);
  const [followed, setFollowed] = useState(0);
  const [views, setViews] = useState(0);

  const [joinRoyaled, setJoinRoyaled] = useState(false);
  const [joinRoyalNumber, setJoinRoyalNumber] = useState(0);

  const category = [
    { name: "QR Menu", icon: <IoQrCodeOutline /> },
    { name: "Loyalty", icon: <FaRegAddressCard /> },
    { name: "Best Deals", icon: <TiTags /> },
  ];
  const [operator, setOperator] = useState<Operator[]>([]);

  const { id } = useParams<{ id: string }>();

  const videoVariable = {
    videoId: id,
  };

  const getOperator = async () => {
    try {
      // const response = await operatorApi.getOperatorInfo({ operator: id });

      const response = await axios.get(
        `${baseURL}/api/products/operatorInfoinAuthor?operator=${id}`
      );

      // const response = await axios.get(
      //   `${baseURL}/api/products/operatorInfoinAuthor`,
      //   {
      //     params: { operator: id },
      //   }
      // );

      if (response.data.success && response.data.operator) {
        const operatorData = Array.isArray(response.data.operator)
          ? response.data.operator
          : [response.data.operator];

        const items = operatorData.map((item: any) => ({
          _id: item._id,
          name: item.name,
          email: item.email,
          password: item.password,
          isAdmin: item.isAdmin,
          coverPhoto: item.coverPhoto,
          photo: item.photo,
          phone: item.phone,
          address: item.address,
          bio: item.bio,
          quote: item.quote,
          createdAt: item.createdAt,
        }));

        setOperator(items);
      }

      console.log({ operator });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Ensure loading is stopped after the request
    }
  };

  const getReviewList = async () => {
    axios
      .post(`${baseURL}/api/review/getReviews`, videoVariable)
      .then((response) => {
        if (response.data.success) {
          setCommentLists(response.data.reviews);
        } else {
          alert("Failed to get comments...Dr. Man Ha");
        }
      });
  };

  const getFollowers = async () => {
    let userTo = id;
    let userFrom = user?._id;

    try {
      const subscribeNumberResponse = await axios.post(
        `${baseURL}/api/subscribe/subscribeNumber`,
        { userTo }
      );

      if (subscribeNumberResponse.data.success) {
        setFollows(subscribeNumberResponse.data.subscribeNumber);
      }

      const subscribedResponse = await axios.post(
        `${baseURL}/api/subscribe/subscribed`,
        { userTo, userFrom }
      );
      if (subscribedResponse.data.success) {
        setFollowed(subscribedResponse.data.subcribed);
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  function nFormatter(num: any, digits: any) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  const getViews = () => {
    axios
      .post(`${baseURL}/api/view/getViews`, { userFrom: user?._id, userTo: id })
      .then((response) => {
        if (response.data.success) {
          setViews(response.data.viewNumber);
        } else {
          alert("Failed to get Views");
        }
      });
  };

  const getRoyalty = () => {
    //GET JOIN ROYAL PROGRAM NUMBER
    let getNumberVariable = {
      userTo: id,
    };
    axios
      .post(`${baseURL}/api/royalprogram/joinNumber`, getNumberVariable)
      .then((response) => {
        if (response.data.success) {
          setJoinRoyalNumber(response.data.joinNumber);
        } else {
          alert("Failed to get join Royal program Number");
        }
      });

    if (user?._id) {
      let joinVariables = {
        userTo: id,
        userFrom: user?._id,
      };

      axios
        .post(`${baseURL}/api/royalprogram/joined`, joinVariables)
        .then((response) => {
          if (response.data.success) {
            // console.log(
            // `${user.displayName} JOINED ROYAL PROGRAM :`,
            // response.data.joined
            // );
            setJoinRoyaled(response.data.joined);
          } else {
            alert("Failed to get Subscribed Information");
          }
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getOperator();
    getReviewList();
    getFollowers();
    getViews();
    getRoyalty();
  }, []);

  useEffect(() => {
    if (!user) {
      dispatch(setAuthModalOpen(true)); // ✅ Dispatch inside useEffect, not return
    }
  }, [user, dispatch]);

  if (!user) return null; // ✅ Return null instead of a Redux action

  const handleMessageSubmit = async () => {
    const groupTitle = `${operator[0].name} & ${user?.displayName}`;
    const userId = user?._id;
    const sellerId = operator[0]._id;

    try {
      const response = await chatApi.createNewConversation({
        groupTitle,
        userId,
        sellerId,
      });

      // console.log("Conversation created:", response);
      navigate(`/userInbox`);
    } catch (error) {
      console.error("Failed to create new conversation:", error);
    }
  };

  const updateComment = (newComment: any) => {
    setCommentLists(CommentLists.concat(newComment));
  };

  const updateSubcribe = (newSubcribe: any) => {
    setFollows(newSubcribe); // Use the passed value from Subscriber component
  };

  const updateRoyalty = (newMember: any) => {
    setJoinRoyalNumber(newMember);
  };

  const renderSidebar = () => {
    return (
      <>
        <div className="relative lg:h-[100vh] lg:w-[1280px]  bg-cover bg-center mx-auto  sm:h-[80vh]">
          <div
            className="absolute inset-0 h-[250px] rounded-t-[10px] bg-center bg-black bg-opacity-70"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${
                operator[0]?.coverPhoto || "/hero/lokalai24-25.jpg"
              })`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          {/* <div className="absolute inset-0 bg-gradient-to-t from-black bg-neutral-950 to-blue-950 opacity-60"></div> */}
          {/* <div className="absolute inset-0 custom-gradient"></div> */}
          <div className="relative flex flex-col items-center justify-center text-center space-y-4 lg:space-y-8 py-12 lg:py-20 relative">
            <div className="mt-28">
              <Avatar
                imgUrl={operator[0]?.photo}
                sizeClass="w-36 h-36"
                hasChecked
                containerClassName="border-4 border-red-500 bg-red-300" // Red border around avatar
              />
            </div>
            <h3 className="text-4xl font-bold text-red-500 mt-10 mx-10 px-4">
              {operator[0]?.name || "Loading..."}
            </h3>
            <span className="text-gray-400">
              {follows} Followers , {joinRoyalNumber} joined Royalty,{" "}
              <View userTo={id} userFrom={user?._id} />
            </span>

            <Bio bio={operator[0]?.bio} />
            <div></div>

            <div className="flex space-x-8 mt-4">
              <div
                className="text-center"
                onClick={() =>
                  document
                    .getElementById("comments-section")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                <h2 className="text-2xl font-bold">{CommentLists.length}</h2>
                <p className="text-gray-300">Comments</p>
              </div>
              <div className="text-center">
                {/* <h2 className="text-2xl font-bold">{follows}</h2> */}
                {user?._id && (
                  <Subscriber
                    userTo={id || ""}
                    userFrom={user._id}
                    refreshFunction={updateSubcribe}
                  />
                )}
              </div>
              {user?._id ? (
                <Royalty
                  userTo={id || ""}
                  operatorname={user?._id || ""}
                  refreshFunction={updateRoyalty}
                />
              ) : null}
            </div>
            <div className="space-y-4 mt-4 ">
              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                <span className="text-gray-300 dark:text-neutral-300  max-w-xs break-words">
                  {operator[0]?.address}
                </span>
              </div>
              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                </svg>
                <span className="text-gray-300 dark:text-neutral-300">
                  {operator[0]?.phone}
                </span>
              </div>

              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <span className="text-gray-300 dark:text-neutral-300">
                  Joined{" "}
                  {operator[0]?.createdAt
                    ? format(new Date(operator[0]?.createdAt), "MMMM dd, yyyy")
                    : "Unknown"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center  -mt-8 gap-1">
          <button
            className="btn-round bg-red-500 hover:bg-red-600 text-white text-lg px-8 py-3 rounded-full z-10 neon-black-box"
            onClick={handleMessageSubmit}
          >
            Message
          </button>

          <button className="rounded-full z-10 bg-neutral-200 text-neutral-600 hover:text-neutral-900 hover:bg-neutral-100 p-0  w-14 h-14 flex items-center justify-center">
            <CiFacebook size={24} color="#000" />
          </button>
          <button className="rounded-full z-10 bg-neutral-200 text-neutral-600 hover:text-neutral-900 hover:bg-neutral-100 p-0  w-14 h-14 flex items-center justify-center">
            <CiInstagram size={24} color="#000" />
          </button>
          <button className="rounded-full z-10 bg-neutral-200 text-neutral-600 hover:text-neutral-900 hover:bg-neutral-100 p-0  w-14 h-14 flex items-center justify-center">
            <CiYoutube size={24} color="#000" />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8"></div>
      </>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap_no ">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl font-bold text-center mt-6">
            Be Bau's listings
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Be Bau's service is very rich, 5 star reviews help him to be more
            branded.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex overflow-x-auto justify-center">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`rounded-full p-2 w-16 h-16 flex items-center justify-center rounded-full focus:outline-none my-4 mx-2 ${
                      selected
                        ? "bg-red-500 text-secondary-50 shadow-lg neon-black-box"
                        : "bg-neutral-200 text-neutral-600 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    <IoQrCodeOutline size={20} />
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`rounded-full p-2 w-16 h-16 flex items-center justify-center rounded-full focus:outline-none my-4 mx-2 ${
                      selected
                        ? "bg-red-500 text-secondary-50 neon-black-box"
                        : "bg-neutral-200 text-neutral-600 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    <FaRegAddressCard size={20} />
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`rounded-full p-2 w-16 h-16 flex items-center justify-center rounded-full focus:outline-none my-4 mx-2 ${
                      selected
                        ? "bg-red-500 text-secondary-50 neon-black-box"
                        : " bg-neutral-200 text-neutral-600 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    <TiTags size={20} />
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="">
                  <p>
                    Inspired by Loklai, Royal Vietnamese Café & Restaurant food
                    menu is light, sunny and made for snacking, sharing or
                    savouring solo. Fresh, seasonal and sustainable, the menu
                    showcases suppliers and provenance and seafood is the star.
                    View the full dynamic menu
                    <Link
                      to={`/operator/menu/${id}`}
                      className="text-blue-700 underline font-bold"
                    >
                      {" "}
                      here
                    </Link>
                  </p>
                </div>

                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_STAY_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <StayCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 4).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap_no" id="comments-section">
        <Comments
          CommentLists={CommentLists}
          postId={id}
          refreshFunction={updateComment}
          userFrom={user?._id}
          author={operator[0]?.name || "Loading..."}
        />
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Lokalai || Author Page</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <main className="mt-0 mb-24 lg:mb-32 flex flex-col item-center justify-center">
          <div className="block flex-grow mb-24 lg:mb-0">
            <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
          </div>
          <div className="container space-y-8 lg:space-y-10 flex-shrink-0">
            {renderSection1()}
            {renderSection2()}
          </div>
        </main>
      )}
    </div>
  );
};

export default AuthorPage;
