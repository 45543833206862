import React, { useEffect, useState } from "react";
// import { Tooltip, Icon } from 'antd';
import Axios from "axios";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline"; // Outline version
import { baseURL } from "configs/axiosConfig";
import Avatar from "shared/Avatar/Avatar";
import { useSelector } from "react-redux";

import { RiThumbUpLine } from "react-icons/ri";

function LikeDislikes(props) {
  // console.log("like props", props);
  const user = useSelector((state) => state.auth.user);
  const [Likes, setLikes] = useState(0);
  const [Dislikes, setDislikes] = useState(0);
  const [LikeAction, setLikeAction] = useState(null);
  const [DislikeAction, setDislikeAction] = useState(null);
  let variable = {};

  if (props.video) {
    variable = { videoId: props.videoId, userId: props.userId };
  } else {
    variable = { commentId: props.commentId, userId: props.userId };
  }

  useEffect(() => {
    Axios.post(`${baseURL}/api/like/getLikes`, variable).then((response) => {
      if (response.data.success) {
        //How many likes does this video or comment have
        setLikes(response.data.likes.length);

        //if I already click this like button or not
        response.data.likes.map((like) => {
          if (like.userId === props.userId) {
            setLikeAction("liked");
          }
        });
      } else {
        alert("Failed to get likes");
      }
    });

    Axios.post(`${baseURL}/api/like/getDislikes`, variable).then((response) => {
      if (response.data.success) {
        //How many likes does this video or comment have
        setDislikes(response.data.dislikes.length);

        //if I already click this like button or not
        response.data.dislikes.map((dislike) => {
          if (dislike.userId === props.userId) {
            setDislikeAction("disliked");
          }
        });
      } else {
        alert("Failed to get dislikes");
      }
    });
  }, []);

  const onLike = () => {
    if (LikeAction === null) {
      Axios.post(`${baseURL}/api/like/upLike`, variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes + 1);
          setLikeAction("liked");

          //If dislike button is already clicked

          if (DislikeAction !== null) {
            setDislikeAction(null);
            setDislikes(Dislikes - 1);
          }
        } else {
          alert("Failed to increase the like");
        }
      });
    } else {
      Axios.post(`${baseURL}/api/like/unLike`, variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes - 1);
          setLikeAction(null);
        } else {
          alert("Failed to decrease the like");
        }
      });
    }
  };

  const onDisLike = () => {
    if (DislikeAction !== null) {
      Axios.post(`${baseURL}/api/like/unDisLike`, variable).then((response) => {
        if (response.data.success) {
          setDislikes(Dislikes - 1);
          setDislikeAction(null);
        } else {
          alert("Failed to decrease dislike");
        }
      });
    } else {
      Axios.post(`${baseURL}/api/like/upDisLike`, variable).then((response) => {
        if (response.data.success) {
          setDislikes(Dislikes + 1);
          setDislikeAction("disliked");

          //If dislike button is already clicked
          if (LikeAction !== null) {
            setLikeAction(null);
            setLikes(Likes - 1);
          }
        } else {
          alert("Failed to increase dislike");
        }
      });
    }
  };

  return (
    <React.Fragment>
      <span key="comment-basic-like" className="flex items-center space-x-2">
        <button
          onClick={onLike}
          className={`text-lg ${
            LikeAction === "liked" ? "text-blue-500" : "text-gray-500"
          } hover:text-blue-600`}
          aria-label="Like"
        >
          <HandThumbUpIcon className="h-6 w-6" />
        </button>
        <span className="text-sm text-gray-700">{Likes}</span>
        {LikeAction === "liked" && (
          <Avatar
            sizeClass="h-6 w-6 text-lg"
            radius="rounded-full"
            userName={user?.displayName}
            imgUrl={user?.photo}
          />
        )}
      </span>

      <span
        key="comment-basic-dislike"
        className="flex items-center space-x-2 ml-4"
      >
        <button
          onClick={onDisLike}
          className={`text-lg ${
            DislikeAction === "disliked" ? "text-red-500" : "text-gray-500"
          } hover:text-red-600`}
          aria-label="Dislike"
        >
          <HandThumbDownIcon className="h-6 w-6" />
        </button>
        <span className="text-sm text-gray-700">{Dislikes}</span>
        {DislikeAction === "disliked" && (
          <Avatar
            sizeClass="h-6 w-6 text-lg"
            radius="rounded-full"
            userName={user?.displayName}
            imgUrl={user?.photo}
          />
        )}
      </span>
    </React.Fragment>
  );
}

export default LikeDislikes;
