import { publicClient, privateClient } from "../../configs/axiosConfig";
import operatorEndpoints from "./operatorEndpoints";

const operatorApi = {
  getOperatorInfo: async ({ operator }) => {
    try {
      const response = await publicClient.get(
        operatorEndpoints.getOperatorInfo,
        {
          params: { operator }, // Correct way to pass query params
        }
      );
      // const response = await publicClient.post(
      //   operatorEndpoints.getOperatorInfo,
      //   { operator } // Correct way to pass query params
      // );

      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  getListOperator: async (id) => {
    try {
      const response = await privateClient.post(
        operatorEndpoints.getListOperator,
        { userFrom: id }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },

  subscribe: async ({ userTo, userFrom }) => {
    try {
      const response = await publicClient.post(operatorEndpoints.subscribe, {
        userTo,
        userFrom,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Subscription failed");
    }
  },

  unsubscribe: async ({ userTo, userFrom }) => {
    try {
      const response = await publicClient.post(operatorEndpoints.unsubscribe, {
        userTo,
        userFrom,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Unsubscription failed");
    }
  },
};

export default operatorApi;
