import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import logo_mobile_w from "../../asset/logo_mobile_w.png";
import logo_mobile_b from "../../asset/logo_mobile_b.png";
import logo_desktop_w from "../../asset/logo_desktop_w.png";
import logo_desktop_b from "../../asset/logo_desktop_b.png";

function LogoSignIn() {
  const [trigger, setTrigger] = useState(false);

  // Handle scroll trigger
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setTrigger(true);
    } else {
      setTrigger(false);
    }
  };

  // Add event listener for scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Link to="/">
      <div className="flex items-center">
        {/* Desktop logo */}
        {/* {!trigger ? (
          <img src={logo_desktop_b} height={20} className="hidden sm:block" />
        ) : (
          <img src={logo_desktop_w} height={20} className="hidden sm:block" />
        )} */}

        {/* Mobile logo */}
        {!trigger ? (
          <img src={"/image/logo_mobile_b.png"} className="h-12 w-auto" />
        ) : (
          <img src={"/image/logo_mobile_b.png"} className="h-12 w-auto" />
        )}
      </div>
    </Link>
  );
}

export default LogoSignIn;
