import { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import userApi from "../../api/user/user.api";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { setUser } from "../../redux/features/userSlice";

import { login } from "../../redux/authSlice";

// import Google from "./img/google.png";
// import Facebook from "./img/facebook.png";
// import Github from "./img/github.png";
// import insta from "./img/insta50.png";
// import { Client_Google_URL } from "../../api/client/URL";

const SigninForm = ({ switchAuthState }) => {
  const dispatch = useDispatch();
  const [isLoginRequest, setIsLoginRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const google = () => {
  //   window.open(`${Client_Google_URL}auth/google`, "_self");
  // };

  // const github = () => {
  //   console.log("Github login clicked");
  // };

  // const facebook = () => {
  //   console.log("Facebook login clicked");
  // };

  const signinForm = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Username must be at least 3 characters")
        .required("Username is required"),
      password: Yup.string()
        .min(3, "Password must be at least 3 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      setErrorMessage("");
      setIsLoginRequest(true);
      let responseLogin = dispatch(login(values));

      if (responseLogin) {
        signinForm.resetForm();
        setIsLoginRequest(false);
        dispatch(setAuthModalOpen(false));
        toast.success("Sign in successful");
      }

      // if (err) setErrorMessage(err.message);
    },
  });

  return (
    <form onSubmit={signinForm.handleSubmit} className="space-y-4">
      {/* Username Input */}
      <input
        type="text"
        name="username"
        placeholder="Email"
        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        value={signinForm.values.username}
        onChange={signinForm.handleChange}
      />
      {signinForm.touched.username && signinForm.errors.username && (
        <p className="text-red-500 text-sm">{signinForm.errors.username}</p>
      )}

      {/* Password Input */}
      <input
        type="password"
        name="password"
        placeholder="Password"
        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        value={signinForm.values.password}
        onChange={signinForm.handleChange}
      />
      {signinForm.touched.password && signinForm.errors.password && (
        <p className="text-red-500 text-sm">{signinForm.errors.password}</p>
      )}

      {/* Sign In Button */}
      <button
        type="submit"
        className="w-full bg-gray-300 hover:bg-gray-500 text-black py-2 rounded-md transition duration-300"
        disabled={isLoginRequest}
      >
        {isLoginRequest ? "Signing in..." : "Sign In"}
      </button>

      {/* Divider */}
      <div className="flex items-center justify-center my-4">
        <span className="text-gray-500 px-2">OR</span>
      </div>

      {/* Social Login Buttons */}
      {/* <div className="flex justify-center space-x-4">
        <button onClick={google} className="p-2 rounded-full border">
          <img src={Google} alt="Google Login" className="w-8 h-8" />
        </button>
        <button onClick={facebook} className="p-2 rounded-full border">
          <img src={Facebook} alt="Facebook Login" className="w-8 h-8" />
        </button>
        <button onClick={github} className="p-2 rounded-full border">
          <img src={insta} alt="GitHub Login" className="w-8 h-8" />
        </button>
      </div> */}

      {/* Sign Up Link */}
      <button
        type="button"
        onClick={() => switchAuthState()}
        className="w-full text-center text-gray-700 hover:text-gray-900 mt-4"
      >
        Sign Up
      </button>

      {/* Error Message */}
      {errorMessage && (
        <p className="text-red-500 text-sm text-center">{errorMessage}</p>
      )}
    </form>
  );
};

export default SigninForm;
