import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "configs/axiosConfig";
import operatorApi from "api/operator/operator.api";
import { BellAlertIcon } from "@heroicons/react/24/outline";

function Subscriber(props) {
  const { userTo, userFrom, refreshFunction } = props;

  const [subscribeNumber, setSubscribeNumber] = useState(0);
  const [subscribed, setSubscribed] = useState(false);
  const [CommentLists, setCommentLists] = useState([]);

  const onSubscribe = async () => {
    const subscribeVariables = { userTo, userFrom };
    let response;

    try {
      if (subscribed) {
        const response = await axios.post(
          `${baseURL}/api/subscribe/unSubscribe`,
          subscribeVariables
        );
        // response = await operatorApi.unsubscribe({ userTo, userFrom });

        if (response.data.success) {
          setSubscribeNumber(subscribeNumber - 1);
          setSubscribed(false);
          if (refreshFunction) refreshFunction(subscribeNumber - 1); // Triggering the refresh function after successful unsubscribe
          // if (props.refreshFunction) {
          //   props.refreshFunction(false); // Send false when unsubscribing
          // }
        } else {
          alert("Failed to unsubscribe");
        }
      } else {
        const response = await axios.post(
          `${baseURL}/api/subscribe/subscribe`,
          subscribeVariables
        );

        // response = await operatorApi.subscribe({ userTo, userFrom });

        if (response.data.success) {
          setSubscribeNumber(subscribeNumber + 1);
          setSubscribed(true);
          if (refreshFunction) refreshFunction(subscribeNumber + 1); // Triggering the refresh function after successful subscribe
        } else {
          alert("Failed to subscribe");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const subscribeNumberResponse = await axios.post(
          `${baseURL}/api/subscribe/subscribeNumber`,
          { userTo, userFrom }
        );

        if (subscribeNumberResponse.data.success) {
          setSubscribeNumber(subscribeNumberResponse.data.subscribeNumber);
        }

        const subscribedResponse = await axios.post(
          `${baseURL}/api/subscribe/subscribed`,
          { userTo, userFrom }
        );
        if (subscribedResponse.data.success) {
          setSubscribed(subscribedResponse.data.subcribed);
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchSubscriptionData();
  }, [userTo, userFrom]);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex justify-center items-center relative border-0">
      {/* Tooltip */}
      {subscribed && isHovered && (
        <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded opacity-100 transition-opacity duration-300">
          Followed
        </span>
      )}

      <button
        onClick={onSubscribe}
        onMouseEnter={() => setIsHovered(true)} // Show tooltip & ring animation
        onMouseLeave={() => setIsHovered(false)} // Hide tooltip & stop animation
        className={`px-6 py-3 rounded-full text-lg font-semibold shadow-lg transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-4 ${
          subscribed
            ? "hover:bg-red-500 focus:ring-white-300 "
            : "bg-gradient-to-r from-red-500 to-pink-500 text-white hover:from-pink-500 hover:to-red-500 focus:ring-red-300"
        }`}
      >
        {subscribed ? (
          <BellAlertIcon
            className={`h-6 w-6 transition duration-300 ${
              isHovered ? "animate-wiggle" : "text-red-500"
            }`}
          />
        ) : (
          "Follow"
        )}
      </button>
    </div>
  );
}

export default Subscriber;
