import React from "react";
import QRCode from "qrcode.react"; // Assuming you use `qrcode.react` for QR Code generation.

const LocationModal = ({ operator, expanded, handleExpandClick }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        expanded ? "block" : "hidden"
      }`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={handleExpandClick}
      ></div>

      <div className="relative p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
        {/* Avatar section */}
        <div className="flex items-center justify-center mt-[-5rem]">
          <div className="relative">
            <div className="absolute right-0 bottom-0 w-6 h-6 rounded-full bg-green-500 z-10"></div>
            <img
              src={operator[0].photo}
              alt="operator avatar"
              className="w-24 h-24 border-2 border-black object-cover rounded-full backdrop-blur-sm"
            />
          </div>
        </div>

        {/* Name Section */}
        <div className="flex flex-col items-center mt-5">
          <p className="text-xl font-bold uppercase text-center text-black">
            {operator[0].name}
          </p>
        </div>

        {/* Location Map Section */}
        <div className="mt-4">
          <h3 className="text-center font-bold text-lg text-gray-800">
            Location
          </h3>
          <div className="relative w-full h-[250px] rounded-lg overflow-hidden mt-2">
            <iframe
              className="w-full h-full border-0"
              src={`https://www.google.com/maps?q=${encodeURIComponent(
                operator[0].address
              )}&output=embed`}
              allowFullScreen
              loading="lazy"
            ></iframe>
            {/* Location Button */}
            <a
              href={`https://www.google.com/maps?q=${encodeURIComponent(
                operator[0].address
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute bottom-2 right-2 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 flex items-center"
            >
              📍 Open in Maps
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
