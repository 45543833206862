import { FaShoppingCart } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setIsCartOpen } from "../../../redux/features/cartState";
import { useDispatch, useSelector } from "react-redux";

const FloatingCartButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);

  const cartCount = cart.length;

  return (
    <button
      className="fixed bottom-16 right-6 bg-red-500 text-white p-4 rounded-full shadow-lg flex items-center justify-center hover:bg-red-600 transition-all duration-300 neon-black-box z-[60]"
      onClick={() => dispatch(setIsCartOpen({}))}
    >
      <FaShoppingCart size={24} />
      {cartCount > 0 && (
        <span className="absolute -top-1 -right-1 bg-black text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
          {cartCount}
        </span>
      )}
    </button>
  );
};

export default FloatingCartButton;
