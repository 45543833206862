import React, { useState } from "react";
// import { Comment, Avatar, Button, Input, Tooltip } from "antd";
import Axios from "axios";
import { useSelector } from "react-redux";
import LikeDislikes from "./LikeDislikes";
import moment from "moment";
import { format } from "timeago.js";
import { baseURL } from "configs/axiosConfig";
import Avatar from "shared/Avatar/Avatar";
import { FaStar } from "react-icons/fa";
// import LikeDislikes from './LikeDislikes';
// const { TextArea } = Input;
function SingleComment(props) {
  console.log("single comment --->", props);
  const user = useSelector((state) => state.auth.user);
  console.log("user --->", user);
  const [CommentValue, setCommentValue] = useState("");
  const [OpenReply, setOpenReply] = useState(false);

  const handleChange = (e) => {
    setCommentValue(e.currentTarget.value);
  };

  const openReply = () => {
    setOpenReply(!OpenReply);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const variables = {
      writer: user._id,
      postId: props.postId,
      responseTo: props.comment._id,
      content: CommentValue,
    };

    Axios.post(`${baseURL}/api/review/saveReview`, variables).then(
      (response) => {
        if (response.data.success) {
          setCommentValue("");
          setOpenReply(!OpenReply);
          props.refreshFunction(response.data.result);
        } else {
          alert("Failed to save Comment");
        }
      }
    );
  };

  const actions = [
    <LikeDislikes
      comment
      commentId={props.comment._id}
      userId={localStorage.getItem("userId")}
    />,
    <span onClick={openReply} key="comment-basic-reply-to">
      Reply to{" "}
    </span>,
  ];

  return (
    <div className="mb-4">
      {/* Comment Display */}

      <div
        className="nc-CommentListing flex space-x-4"
        data-nc-id="CommentListing"
      >
        <div className="pt-0.5">
          <Avatar
            sizeClass="h-10 w-10 text-lg"
            radius="rounded-full"
            userName={props.comment.writer.displayName}
            imgUrl={props.comment.writer.photo}
          />
        </div>
        <div className="flex-grow">
          <div className="flex justify-between space-x-3">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                <span>{props.comment.writer.displayName}</span>

                <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                  {` review in `}
                </span>
                <a href="/">{props.title}</a>
              </div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
                {format(props.comment.createdAt)}{" "}
              </span>
            </div>

            <div className="flex space-x-1 mb-4">
              {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                  key={star}
                  size={16}
                  className={`cursor-pointer transition ${
                    props.comment.rate >= star
                      ? "text-yellow-500"
                      : "text-gray-300"
                  }`}
                />
              ))}
            </div>
          </div>
          <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
            {props.comment.content}
          </span>
          <div className="mt-2 flex space-x-3 text-teal-600">
            <LikeDislikes
              comment
              commentId={props.comment._id}
              userId={user?.id}
            />
            <span onClick={openReply} className="cursor-pointer text-sm">
              Reply to
            </span>
          </div>
        </div>
      </div>

      {/* Reply Form */}
      {OpenReply && (
        <form className="flex items-center space-x-3 mt-4" onSubmit={onSubmit}>
          <textarea
            className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={handleChange}
            value={CommentValue}
            placeholder="Write a comment..."
          />
          <button
            type="submit"
            className="px-4 py-2 bg-yellow-300 text-teal-700 rounded-md text-xs font-medium hover:bg-yellow-400"
          >
            Comment
          </button>
        </form>
      )}
    </div>
  );
}

export default SingleComment;
