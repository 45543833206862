import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "api/user/user.api";

// Interface cho User và trạng thái Auth
interface User {
  _id: string;
  photo: string;
  dateOfBirth: string;
  gender: string;
  phone: string;
  bio: string;
  displayName: string;
  username: string;
  token: string;
}

interface LoginPayload {
  username: string;
  password: string;
}

interface SignUpPayload {
  username: string;
  password: string;
  confirmPassword: string;
  displayName: string;
  photo: string;
}

interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }: LoginPayload, { rejectWithValue }) => {
    try {
      const response = await userApi.signin({ username, password });
      localStorage.setItem("token", response.token);

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (
    { username, password, confirmPassword, displayName, photo }: SignUpPayload,
    { rejectWithValue }
  ) => {
    if (password !== confirmPassword) {
      return rejectWithValue("Passwords do not match");
    }

    try {
      const response = await userApi.signup({
        username,
        password,
        confirmPassword,
        displayName,
        photo,
      });
      localStorage.setItem("token", response.token);

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Signup failed");
    }
  }
);

// Tạo authSlice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.error = null;
      localStorage.removeItem("token"); // Xóa token khi người dùng logout
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log(action.payload);

        state.isLoading = false;
        state.isAuthenticated = true;

        state.user = {
          _id: action.payload._id,
          photo: action.payload.photo,
          dateOfBirth: action.payload.dateOfBirth,
          gender: action.payload.gender,
          phone: action.payload.phone,
          bio: action.payload.bio,
          displayName: action.payload.displayName,
          username: action.payload.username,
          token: action.payload.token,
        };

        state.token = action.payload.token;
      })
      .addCase(signup.fulfilled, (state, action) => {
        console.log(action.payload);

        state.isLoading = false;
        state.isAuthenticated = true;

        state.user = {
          _id: action.payload._id,
          photo: action.payload.photo,
          dateOfBirth: action.payload.dateOfBirth,
          gender: action.payload.gender,
          phone: action.payload.phone,
          bio: action.payload.bio,
          displayName: action.payload.displayName,
          username: action.payload.username,
          token: action.payload.token,
        };

        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
