import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import LogoSignIn from "./LogoSignIn";
import SigninForm from "./SigninForm";
import SignupForm from "./SignupForm";

const actionState = {
  signin: "signin",
  signup: "signup",
};

const AuthModal = () => {
  const { authModalOpen } = useSelector((state) => state.authModal);

  // console.log({ authModalOpen });
  const dispatch = useDispatch();
  const [action, setAction] = useState(actionState.signin);

  useEffect(() => {
    if (authModalOpen) setAction(actionState.signin);
  }, [authModalOpen]);

  const handleClose = () => dispatch(setAuthModalOpen(false));
  const switchAuthState = (state) => setAction(state);

  if (!authModalOpen) return null; // Don't render when closed

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleClose} // Click outside to close
    >
      {/* Modal Content */}
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative m-5"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          onClick={handleClose}
        >
          ✕
        </button>

        {/* Logo */}
        <div className="text-center mb-6">
          <LogoSignIn />
        </div>

        {/* Auth Forms */}
        {action === actionState.signin && (
          <SigninForm
            switchAuthState={() => switchAuthState(actionState.signup)}
          />
        )}

        {action === actionState.signup && (
          <SignupForm
            switchAuthState={() => switchAuthState(actionState.signin)}
          />
        )}
      </div>
    </div>
  );
};

export default AuthModal;
