import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { setAuthModalOpen } from "../../redux/authModalSlice";
import { toast } from "react-toastify";
import { baseURL } from "configs/axiosConfig";
import { CheckCircleIcon, UserAddIcon } from "@heroicons/react/20/solid";
import { BellAlertIcon, TrophyIcon } from "@heroicons/react/24/outline";

function Royalty(props) {
  // console.log("subcribe props :", { props });

  const user = useSelector((state) => state.auth.user);
  // console.log({ user });
  const dispatch = useDispatch();

  const { userTo, userFrom, refreshFunction } = props;

  const [SubscribeNumber, setSubscribeNumber] = useState(0);
  const [Subscribed, setSubscribed] = useState(false);
  const [joinRoyaled, setJoinRoyaled] = useState(false);
  const [joinRoyalNumber, setJoinRoyalNumber] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const subscribeNumberVariables = { userTo: userTo };
    //GET FOLLOW (SUBCRIBER) NUMBER
    axios
      .post(
        `${baseURL}/api/subscribe/subscribeNumber`,
        subscribeNumberVariables
      )
      .then((response) => {
        if (response.data.success) {
          setSubscribeNumber(response.data.subscribeNumber);
        } else {
          alert("Failed to get subscriber Number");
        }
      });
    // if (!user) return dispatch(setAuthModalOpen(true));
    // GET SUBCRIBER INFORMATION (SUBCRIBE OR NOT)

    if (user) {
      const subscribeInformationVariables = {
        userTo: userTo,
        userFrom: user.id,
      };
      axios
        .post(
          `${baseURL}/api/subscribe/subscribed`,
          subscribeInformationVariables
        )
        .then((response) => {
          if (response.data.success) {
            setSubscribed(response.data.subcribed);
            // console.log("response.data.subcribed", response.data.subcribed);
            // toast.info("successull get subcribed  of the user");
          } else {
            alert("Failed to get Subscribed Information");
          }
        });
    }

    //GET JOIN ROYAL PROGRAM NUMBER
    axios
      .post(`${baseURL}/api/royalprogram/joinNumber`, subscribeNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setJoinRoyalNumber(response.data.joinNumber);
        } else {
          alert("Failed to get join Royal program Number");
        }
      });
  }, []);
  let joinVariables = {
    userTo: userTo,
    userFrom: user.id,
  };

  axios
    .post(`${baseURL}/api/royalprogram/joined`, joinVariables)
    .then((response) => {
      if (response.data.success) {
        // console.log(
        // `${user.displayName} JOINED ROYAL PROGRAM :`,
        // response.data.joined
        // );
        setJoinRoyaled(response.data.joined);
      } else {
        alert("Failed to get Subscribed Information");
      }
    });

  const onSubscribe = () => {
    console.log({ Subscribed });

    let subscribeVariables = {
      userTo: userTo,
      userFrom: user.id,
    };

    console.log(subscribeVariables);
    if (Subscribed) {
      //when we are already subscribed
      axios
        .post(`${baseURL}/api/subscribe/unSubscribe`, subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            setSubscribeNumber(SubscribeNumber - 1);
            setSubscribed(!Subscribed);
            console.log(response.data.success);
          } else {
            alert("Failed to unsubscribe");
          }
        });
    } else {
      // when we are not subscribed yet

      axios
        .post(`${baseURL}/api/subscribe/subscribe`, subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            setSubscribeNumber(SubscribeNumber + 1);
            setSubscribed(!Subscribed);
          } else {
            alert("Failed to subscribe");
          }
        });
    }
  };

  const onJoinRoyal = () => {
    let joinVariables = {
      userTo: userTo,
      userFrom: user.id,
    };

    console.log({ joinVariables });

    //GET USER ALREADY JOINED ROYAL PROGRAM

    if (joinRoyaled) {
      //when users are already joined Royalty Program -> UnJoin
      axios
        .post(`${baseURL}/api/royalprogram/unjoinLoyalty`, joinVariables)
        .then((response) => {
          if (response.data.success) {
            setJoinRoyalNumber(joinRoyalNumber - 1);
            setJoinRoyaled(!joinRoyaled);
            if (refreshFunction) refreshFunction(joinRoyalNumber - 1); // Triggering the refresh function after successful subscribe

            console.log(response.data.success);
          } else {
            alert("Failed to unjoin loyalty program");
          }
        });
    } else {
      // when users are not joint the Royalty Program -> JOINED

      axios
        .post(`${baseURL}/api/royalprogram/joinLoyalty`, joinVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            setJoinRoyalNumber(joinRoyalNumber + 1);
            setJoinRoyaled(!joinRoyaled);
            if (refreshFunction) refreshFunction(joinRoyalNumber + 1); // Triggering the refresh function after successful subscribe
          } else {
            alert("Failed to join loyalty program");
          }
        });
    }
  };

  return (
    <div className="flex justify-center items-center relative border-0">
      {joinRoyaled && isHovered && (
        <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded opacity-100 transition-opacity duration-300">
          Joined Royalty
        </span>
      )}

      {/* {Subscribed && ( */}
      <button
        onClick={onJoinRoyal}
        onMouseEnter={() => setIsHovered(true)} // When mouse enters
        onMouseLeave={() => setIsHovered(false)} // When mouse leaves
        className={`px-6 py-3 rounded-full text-sm font-semibold transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-4 ${
          joinRoyaled
            ? "hover:bg-red-500 focus:ring-white-300 "
            : "bg-gradient-to-r from-red-500 to-pink-500 text-white hover:from-pink-500 hover:to-red-500 focus:ring-red-300"
        }`}
      >
        {joinRoyaled ? (
          <span className="text-yellow-500 text-lg animate-wiggle">🏅</span>
        ) : (
          "Royalty"
        )}
      </button>
      {/* )} */}
    </div>
  );
}

export default Royalty;
