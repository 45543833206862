import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Box,
  Typography,
  useTheme,
  Button,
  Zoom,
} from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
import {
  addToCart,
  setIsAddtoCartOpen,
} from "../../../redux/features/cartState";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { CircleStackIcon, XCircleIcon } from "@heroicons/react/24/outline";

const MenuItem = ({ item }) => {
  // console.log({ item });
  // console.log({width})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = useState(false);

  const cart = useSelector((state) => state.cart.cart);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { price } = item;
  const totalPrice = count * price;

  // check if item is Addtocart to be red color
  const cartItem = cart.find((cartItem) => cartItem._id === item._id);
  const itemCountInCart = cartItem ? cartItem.count : 0;
  const isItemInCart = itemCountInCart > 0;

  // console.log({ cartItem });
  // console.log({ itemCountInCart });
  // console.log({ isItemInCart });
  return (
    <div
      onClick={handleExpandClick}
      className={`w-full flex py-4 px-4 items-center justify-between border
        ${isItemInCart ? "border-red-500 bg-red-100" : "border-gray-300"}
      rounded-lg shadow bg-gray-50 hover:bg-gray-200 transition-all relative`}
    >
      <div className="w-9/12  cursor-pointer">
        <p className="text-base sm:text-lg font-semibold">{item.title}</p>
        <p className="text-sm sm:text-base text-gray-700">{item.desc}</p>
      </div>
      <div className="w-3/12 flex justify-center items-end flex-col">
        <p className="text-lg pr-3">${item.price}</p>
      </div>

      {/* Cart Quantity Badge (Top Right Corner) */}
      {isItemInCart && (
        <div className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-tr-lg w-6 h-6 flex items-center justify-center">
          {itemCountInCart}
        </div>
      )}
      {/* Modal */}
      {expanded && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 "
          onClick={handleExpandClick} // Clicking background closes modal
        >
          <div
            className="relative p-8 bg-white rounded shadow-md max-w-lg m-2"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            {/* Close Button */}
            <button
              className="absolute top-5 right-4 text-xl "
              onClick={handleExpandClick}
            >
              <XCircleIcon className="max-w-[24px] w-full text-neutral-500" />
            </button>

            {/* Modal Content */}
            <p className="text-lg sm:text-xl font-semibold text-red-500 mt-5">
              {item.title}
            </p>
            <p className="text-sm sm:text-sm text-gray-700">{item.desc}</p>

            {/* Quantity Section */}
            <div className="flex items-center justify-between bg-white border border-gray-400 rounded px-2 py-1 my-5">
              <legend className="text-sm">Quantity</legend>
              <p className="text-gray-700">{count}</p>
              <div className="flex gap-1">
                <button
                  className="border bg-gray-300 p-1"
                  onClick={() => setCount(Math.max(count - 1, 1))}
                >
                  -
                </button>
                <button
                  className="border bg-gray-300 p-1"
                  onClick={() => setCount(count + 1)}
                >
                  +
                </button>
              </div>
            </div>

            {/* Total Price Section */}
            <div className="flex justify-between items-center mt-2 p-2 border rounded mt-5">
              <legend className="text-sm">Total</legend>
              <p className="ml-1 text-gray-700">${totalPrice}</p>
              <button
                className="px-4 py-1 bg-red-500 rounded text-black hover:bg-gray-600 neon-black-box"
                onClick={() =>
                  dispatch(addToCart({ item: { ...item, count } }))
                }
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuItem;
