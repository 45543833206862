import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { format } from "timeago.js";
import { baseURL } from "configs/axiosConfig";

function View(props) {
  const user = useSelector((state) => state.auth.user);
  const [views, setViews] = useState(0);
  const [viewAction, setViewAction] = useState(false); // Tracks if the view has already been counted

  // Formats the number for views (e.g., 1k, 1M, etc.)
  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  const viewVariablegetViewNumber = {
    userTo: props.userTo,
  };

  useEffect(() => {
    Axios.post(`${baseURL}/api/view/getViews`, viewVariablegetViewNumber).then(
      (response) => {
        if (response.data.success) {
          setViews(response.data.viewNumber);
        } else {
          alert("Failed to get Views");
        }
      }
    );
    const hasViewed = sessionStorage.getItem(`viewed-${props.userTo}`);
    if (!hasViewed) {
      const viewVariable = {
        userTo: props.userTo,
        userFrom: props.userFrom,
      };

      // console.log({ viewVariable });

      // Only increase the view count if it hasn't been done already

      Axios.post(`${baseURL}/api/view/upView`, viewVariable)
        .then((response) => {
          if (response.data.success) {
            setViewAction(true);
            sessionStorage.setItem(`viewed-${props.userTo}`, "true");
          } else {
            alert("Failed to increase views");
          }
        })
        .catch(() => {
          console.error("Error increasing views");
        });
    }
    sessionStorage.clear(`viewed-${props.userTo}`, "true");
  }, [props.userFrom, props.userTo]);

  const Tooltip = ({ title, children }) => {
    return (
      <div className="relative group">
        {children}
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-black text-white text-sm px-2 py-1 rounded">
          {title}
        </div>
      </div>
    );
  };

  return (
    <span className="d-flex flex-row">
      <Tooltip title="View">
        <span style={{ cursor: "auto" }}>{nFormatter(views, 1)}</span>
        <span style={{ paddingRight: "0.5rem" }}> views</span>
      </Tooltip>
      {/* <span>{format(props.date)}</span> */}
    </span>
  );
}

export default View;
