import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import SingleComment from "./SingleComment";
import ReplyComment from "./ReplyComment";
import Avatar from "shared/Avatar/Avatar";
import { StarIcon } from "@heroicons/react/24/solid"; // Solid star
import { StarIcon as StarOutline } from "@heroicons/react/24/outline";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FaStar } from "react-icons/fa";
import { baseURL } from "configs/axiosConfig";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Base CSS for Swiper
import "swiper/css/navigation"; // Optional - for navigation buttons
import "swiper/css/pagination"; // Optional - for pagination
import LikeDislikes from "./LikeDislikes";
import { format } from "timeago.js";
import Button from "shared/Button/Button";
import { toast } from "react-toastify";

// const { TextArea } = Input;

function Comments(props) {
  const user = useSelector((state) => state.auth.user);

  const [Comment, setComment] = useState("");
  const [showcomment, setShowcomment] = useState(false);
  const [showclose, setShowclose] = useState(false);

  const handleChange = (e) => {
    setComment(e.currentTarget.value);
  };

  const useravart = user?.photo;

  const onSubmit = (e) => {
    e.preventDefault();

    if (rating === 0 || Comment.trim() === "") {
      toast.error(
        "Please provide both a rating and a review before submitting."
      );
      return;
    }

    const variables = {
      content: Comment,
      writer: user?._id,
      postId: props.postId,
      rate: rating,
    };

    axios
      .post(`${baseURL}/api/review/saveReview`, variables)
      .then((response) => {
        if (response.data.success) {
          setComment("");
          props.refreshFunction(response.data.result);
        } else {
          alert("Failed to save Comment");
        }
      });

    setRating(0);
    setReview("");
  };

  const handleChange_comments = () => {
    setShowcomment(true);
    setShowclose(true);
  };

  const handleChange_closecomments = () => {
    setShowclose(false);
    setShowcomment(false);
  };

  const hasListingTitle = true;
  const className = "";

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [review, setReview] = useState("");

  const calculateAverageRating = (reviews) => {
    // Filter out reviews that have a valid numeric rate
    const validRatings = reviews.filter(
      (review) => typeof review.rate === "number"
    );

    if (validRatings.length === 0) return 0; // Avoid division by zero

    // Sum up all the ratings
    const totalRate = validRatings.reduce(
      (sum, review) => sum + review.rate,
      0
    );

    // Calculate average
    return (totalRate / validRatings.length).toFixed(1); // Keeping one decimal place
  };

  // Usage
  const averageRating = calculateAverageRating(props.CommentLists);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.3 && rating % 1 <= 0.7;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <div className="flex space-x-1 text-yellow-500">
        {[...Array(fullStars)].map((_, i) => (
          <StarIcon key={i} className="w-6 h-6" />
        ))}
        {hasHalfStar && (
          <div className="relative w-6 h-6">
            <StarIcon className="absolute w-6 h-6 text-yellow-500 " />
            <div className="absolute top-0 right-0 w-3 h-6 bg-white"></div>
          </div>
        )}
        {/* {[...Array(emptyStars)].map((_, i) => (
          <StarOutline key={i} className="w-6 h-6" />
        ))} */}
      </div>
    );
  };

  return (
    <>
      <h2 className="text-2xl font-semibold">
        Reviews ({props.CommentLists.length} reviews)
      </h2>
      <span className="text-yellow-500 text-2xl">
        {renderStars(averageRating)}
      </span>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <form className="flex gap-2" onSubmit={onSubmit}>
        <div className="pt-0.5">
          <Avatar
            sizeClass="h-10 w-10 text-lg"
            radius="rounded-full"
            userName={user?.displayName}
            imgUrl={useravart}
            hasChecked
          />
        </div>
        <div className="flex-grow">
          <div className="flex justify-between space-x-3 mx-2">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                <span>{user?.displayName}</span>
                {hasListingTitle && (
                  <>
                    <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                      {` review in `}
                    </span>
                    <a href="/">{props.author}</a>
                  </>
                )}
              </div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
                {/* {data.date} */}date
              </span>
            </div>
            <div className="flex space-x-1 mb-4">
              {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                  key={star}
                  size={16}
                  className={`cursor-pointer transition ${
                    (hover || rating) >= star
                      ? "text-yellow-500"
                      : "text-gray-300"
                  }`}
                  onMouseEnter={() => setHover(star)}
                  onMouseLeave={() => setHover(0)}
                  onClick={() => setRating(star)}
                />
              ))}
            </div>
          </div>

          <div>
            <textarea
              className="w-full h-10 border-t-0 border-r-0 border-l-0 mb-4 px-2 py-1 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-2"
              onChange={handleChange}
              value={Comment}
              placeholder="...write a public comment"
            />
            <ButtonPrimary
              type="submit"
              className="rounded-md text-sm font-medium border"
              // disabled={rating === 0 || review.trim() === ""}
            >
              Review
            </ButtonPrimary>
          </div>
        </div>
      </form>
      <div>
        {!showcomment && (
          <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
            <div className="max-w-6xl mx-auto p-8">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                autoplay={{ delay: 3000 }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  1024: {
                    slidesPerView: 2,
                  },
                }}
              >
                {props.CommentLists.map((review, index) => (
                  <SwiperSlide key={index}>
                    <section className="bg-white dark:bg-gray-900">
                      <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                        <figure className="max-w-screen-md mx-auto">
                          <svg
                            className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                            viewBox="0 0 24 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                              fill="currentColor"
                            />
                          </svg>
                          <blockquote>
                            <p className="text-2xl font-medium text-gray-900 dark:text-white">
                              {review.content}
                            </p>
                          </blockquote>
                          <figcaption className="flex items-center justify-center mt-6 space-x-3">
                            <img
                              className="w-6 h-6 rounded-full"
                              src={review.writer.photo}
                              alt="profile picture"
                            />
                            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                              <div className="pr-3 font-medium text-gray-900 dark:text-white">
                                {review.writer.displayName}
                              </div>
                              <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                                {format(review.createdAt)}
                              </div>
                            </div>
                            <p className="text-yellow-500">
                              {"★".repeat(review.rate)}{" "}
                              {"☆".repeat(5 - review.rating)}
                            </p>
                          </figcaption>
                        </figure>
                      </div>
                    </section>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}

        <br />
        {/* Comment Lists */}

        <div className="d-flex flex-row">
          {" "}
          <div className="pt-4" onClick={handleChange_comments}>
            <ButtonSecondary>
              View detail {props.CommentLists.length} reviews
            </ButtonSecondary>
          </div>
          <div>
            {showclose && (
              <div className="pt-4" onClick={handleChange_closecomments}>
                <Button>Close comments</Button>
              </div>
            )}
          </div>
        </div>

        {showcomment &&
          props.CommentLists &&
          props.CommentLists.map(
            (comment, index) =>
              !comment.responseTo && (
                <React.Fragment key={index}>
                  {/* <CommentListing /> */}
                  <SingleComment
                    hasListingTitle
                    comment={comment}
                    postId={props.postId}
                    refreshFunction={props.refreshFunction}
                    title={props.author}
                  />
                  <ReplyComment
                    CommentLists={props.CommentLists}
                    postId={props.postId}
                    parentCommentId={comment._id}
                    refreshFunction={props.refreshFunction}
                  />
                </React.Fragment>
              )
          )}
      </div>
    </>
  );
}

export default Comments;
