import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";

const ProtectedPage = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user === null || user === undefined) {
      dispatch(setAuthModalOpen(true)); // Open auth modal if user is not logged in
    }
  }, [user, dispatch]);

  return user ? children : null;
};

export default ProtectedPage;
